<template>
    <Modal :show=true width="w-500">
        <div class="flex justify-between items-center top-0 bg-white pb-2">
            <p class="text-xl font-semibold">Customer Reviews</p>
            <div class="" @click="closeReview" >
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>
        <div :class="{'pr-4':reviewsData.length > 2,'flex items-center justify-center':reviewsData.length == 0 }" class="h-500 px-2 overflow-y-auto scrollbar scrollbar-thumb-rounded-full scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-200 scrollbar-track-rounded-full ">
            <div v-if="reviewsData.length" class="bg-gray-200 rounded my-2 px-5 py-6">
                <div class="flex item-center justify-between mb-2 ">
                    <div class="flex items-center space-x-3">
                        <star-rating :increment="0.5" :rating="(rating != null && rating != 'NaN') ? rating : 0" inactive-color="#fff" border-color="#000" active-color="#DAA520" :border-width="2" :star-size="10" :read-only=true  :show-rating=false />
                        <h1 class="text-sm">{{ (rating != null && rating != 'NaN') ? rating.toFixed(1) : 0 }} out of 5.0</h1> 
                    </div>
                    <p class="text-sm">{{ reviews }} {{ (reviews > 1) ? 'reviews' : 'review' }}</p>
                </div>
                <div class="grid grid-row-1 gap-3 text-sm">
                    <div class="flex items-center space-x-6">
                        <p class="">5 star </p>
                        <div class="relative flex-grow cursor-pointer" >
                            <div class="h-2 rounded-full bg-white" ></div>
                            <div :style="{width :findTotalPercentage(5) }" class="absolute top-0 h-2 rounded-full bg-brand z-10" ></div>
                        </div>
                        <p class="w-6">{{ findTotalPercentage(5) }}</p>
                    </div>
                    <div class="flex items-center space-x-6">
                        <p class="">4 star </p>
                        <div class="relative flex-grow cursor-pointer" >
                            <div class="h-2 rounded-full bg-white" ></div>
                            <div :style="{ width :findTotalPercentage(4) }" class="absolute top-0 h-2 rounded-full bg-brand z-10" ></div>
                        </div>
                        <p class="w-6">{{ findTotalPercentage(4) }}</p>
                    </div>
                    <div class="flex items-center space-x-6">
                        <p class="">3 star </p>
                        <div class="relative flex-grow cursor-pointer" >
                            <div class="h-2 rounded-full bg-white" ></div>
                            <div :style="{ width :findTotalPercentage(3) }" class="absolute top-0 h-2 rounded-full bg-brand z-10" ></div>
                        </div>
                        <p class="w-6">{{ findTotalPercentage(3) }}</p>
                    </div>
                    <div class="flex items-center space-x-6">
                        <p class="">2 star </p>
                        <div class="relative flex-grow cursor-pointer" >
                            <div class="h-2 rounded-full bg-white" ></div>
                            <div :style="{ width :findTotalPercentage(2) }" class="absolute top-0 h-2 rounded-full bg-brand z-10" ></div>
                        </div>
                        <p class="w-6">{{ findTotalPercentage(2) }}</p>
                    </div>
                    <div class="flex items-center space-x-6">
                        <p class="">1 star </p>
                        <div class="relative flex-grow cursor-pointer" >
                            <div class="h-2 rounded-full bg-white" ></div>
                            <div :style="{ width :findTotalPercentage(1) }" class="absolute top-0 h-2 rounded-full bg-brand z-10" ></div>
                        </div>
                        <p class="w-6">{{ findTotalPercentage(1) }}</p>
                    </div>
                </div>
            </div>
            <div class="my-0">
                <div v-if="reviewsData.length">
                    <div v-for="review in reviewsData" :key="review" class="my-8 text-sm">
                        <div class="flex space-x-2 items-center">
                            <div class="h-8 w-8 flex justify-center items-center bg-brand rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                </svg>
                            </div>
                            <div>
                                <p class="font-semibold" >{{ review.reviewerName }}</p>
                                <p class="text-xs">{{ getDatediff(review.createdAt) }}</p>
                            </div>
                        </div>
                        <div class="flex space-x-3 items-center"> 
                            <div class="flex space-x-2 items-center my-2"> 
                                <star-rating :increment="0.5" :rating="review.rating" inactive-color="#fff" border-color="#000" active-color="#DAA520" :border-width="2" :star-size="10" :read-only=true :show-rating=false />
                                <p>{{ review.reviewTitle }}</p>
                            </div>
                        </div>
                        <p class="text-justify">
                            {{ review.reviewComment }}
                        </p>
                        <div v-if="review.adminResponse" class="ml-6 my-2">
                            <div class="flex space-x-2">
                                <div class="h-8 w-8 flex flex-shrink-0 justify-center items-center bg-gray-200 rounded-full">
                                   <img src="@/assets/img/short.png" class="h-4 w-4" alt="">
                                </div>
                                <div >
                                    <div class="flex items-center space-x-1">
                                        <p class="font-semibold">Noshtrybe</p>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-green-600" viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd" d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                            </svg>
                                        </div>
                                    </div>
                                    
                                    <p class="text-sm text-justify">{{ review.adminResponse }}</p>
                                </div>
                            </div>

                        </div>
                        
                    </div>
                </div>
                <div v-else class="h-full flex justify-center">
                    <div class="" >
                        <img src="@/assets/svg/good-review.svg" class="h-24 w-24 mx-auto text-gray-200" alt="">
                        <p class="text-center">No reviews found for this restaurant yet</p>
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>
<script>
import Modal from "@/components/Modal.vue";
import StarRating from 'vue-star-rating';
import datetimeDifference from "datetime-difference";
import moment from 'moment';
export default {
    name:"RatingModal",
    components:{
        Modal,StarRating
    },
    props:['reviewsData','reviews', 'rating'],
    emits:['closePopup'],
    methods:{
        formatThisdate(date){
            return moment(date).format('MMMM Do YYYY, h:mm a');
        },
        findTotalPercentage(type){
            let boundary = type - 0.5
            let Rating = this.reviewsData.filter(item => item.rating >= boundary && item.rating <= type );
            return Math.round(Rating.length / this.reviewsData.length * 100) + '%'
        },
        closeReview(){
            this.$emit('closePopup')
        },
        getDatediff(date){
            let todayDate =  new Date();
            date = new Date(date);
            const result = datetimeDifference(date,todayDate);
            let dif = ""
            if(result.years){
                let singular = (parseInt(result.years) > 1) ? 'years' : 'year'
                let amount = (parseInt(result.years) > 1) ? result.years : 'A'
                dif = `${amount} ${singular} ago`
            }
            else if(result.months){
                let singular = (parseInt(result.months) > 1) ? 'months' : 'month'
                let amount = (parseInt(result.months) > 1) ? result.months : 'A'
                dif = `${amount} ${singular} ago`
            }
            else if(result.days){
                let singular = (parseInt(result.days) > 1) ? 'days' : 'day'
                let amount = (parseInt(result.days) > 1) ? result.days : 'A'
                dif = `${amount} ${singular} ago`
            }
            else if(result.hours){
                let singular = (parseInt(result.hours) > 1) ? 'hours' : 'hour'
                let amount = (parseInt(result.hours) > 1) ? result.hours : 'An'
                dif = `${amount} ${singular} ago`
            }
            else if(result.minutes){
                let singular = (parseInt(result.minutes) > 1) ? 'minutes' : 'minute'
                let amount = (parseInt(result.minutes) > 1) ? result.minutes : 'A'
                dif = `${amount} ${singular} ago`
            }
            return dif;
        }
    }

}
</script>

<style>

</style>