<template>
    <div class="grid mt-4 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <div v-for="count in counts" :key="count" >
             <div class="animate-pulse mt-4 rounded-lg cursor-pointer w-full overflow-hidden bg-white shadow-md text-gray-100">
                <div class="w-full h-300 bg-gray-300 rounded-lg" ></div>
                    <div class=" text-xs mt-4 px-4">
                </div>
                <div class="p-4">
                    <p class="text-sm mt-2 bg-gray-300 w-28 h-4 rounded-full" ></p>
                    <p class="text-sm mt-2 bg-gray-300 h-4 rounded-full" ></p>
                    <div class="text-base h-4 bg-gray-300 rounded-full font-semibold mt-2 transition duration-300 ease-in hover:text-brand"></div>         
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
export default {
    data(){
        return{
            counts: 4
        }
    }
}
</script>

<style>

</style>