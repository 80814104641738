<template>
    <div @dblclick="changeAdress" v-if="changeDelivery" :class="{'lg:py-4': name}" class=" flex space-x-2 bg-white shadow-md items-center flex-nowrap rounded py-4 lg:py-2 px-4 ">
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
            </svg>     
        </div>
        <p class="text-sm">{{ Address }}</p>
        <div class="flex-grow flex justify-end" @click="changeAdress">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
        </div>
    </div>
    <div v-else class="flex space-x-2 bg-white shadow-md rounded ">
        <div :class="{'lg:py-4': name}" class="flex z-30 w-4/5 py-4 lg:py-2 px-4 ">
            <vue-google-autocomplete 
                ref="address"
                id="map"
                v-on:placechanged="getAddressData"
                v-model="inputAddress" 
                @focus="showCancel" 
                @keyup.enter="FilterAddress" 
                country="ng"
                classname="focus:outline-none w-full" 
                placeholder="Enter Delivery Address">
            </vue-google-autocomplete> 
            <div v-if="cancel" @click="Unchange" class="text-gray-400 flex-grow flex justify-end items-center font-bold">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
                </svg>
            </div>
            <div @click="Unchange" class="fixed top-0 left-0 h-full w-full -z-10" ></div>
        </div>
        <div @click="FilterAddress" class="flex justify-end md:justify-center font-semibold rounded-r flex-grow z-30 bg-black text-brand text-sm py-0.5 px-3">
            <button class="flex justify-end items-center" >
                Change
            </button>
        </div>
    </div>
    <div class="text-black" v-if="showPopup">
        <Modal :show="true"> 
            <h1 class="text-xl font-semibold">Do you want to proceed?</h1>
            <p class="mt-4 text-sm" > You have tried to add items from different time belts or different restaurants. Changing any of these will clear your current cart and a new one will be created.</p>
            <div class="flex space-x-4 justify-end">
                <button @click="closeModal" class="bg-gray-200 text-sm py-2 px-3 rounded-sm" >No</button>
                <button @click="clearCart(itemTopush,timebeltTopush)" class="bg-brand text-sm py-2 px-3 rounded-sm" >Yes</button>
            </div>
        </Modal>
    </div>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import Modal from "@/components/Modal.vue";
export default {
    name:['DeliveryAddress'],
    props:['name','newChangedAddress'],
    components:{
        VueGoogleAutocomplete,Modal
    },
    emit:['getNewRestaurants'],
    mounted() {
        this.Address = this.$store.getters.getAddress.address;
    },
    data(){
        return{
            Address:"",
            changeDelivery:true,
            inputAddress:"",
            cancel:false,
            showPopup:false
        }
    },
    methods:{
        changeAdress(){
                this.changeDelivery = false;
        },
        FilterAddress(){
            if (this.inputAddress && this.inputAddress == this.newAddress.address ) {
                if(this.$route.name == 'ResturantsPage'){
                    this.showPopup = true;
                }else{
                    let geoData ={
                        address: this.newAddress.address,
                        latitude:this.newAddress.latitude,
                        longitude: this.newAddress.longitude
                    }
                    this.$store.dispatch('addDeliveryaddress',JSON.stringify(geoData));
                    this.Address = this.inputAddress
                    this.changeDelivery = true;
                    // Emit changes
                    this.$emit('getNewRestaurants');
                }
            }
        },
        showCancel(){
            this.cancel = true
        },
        closeModal(){
            this.showPopup = false;
        },
        clearCart(){
            this.$store.dispatch('emptyCart')
            this.$store.dispatch('emptyResurantName')
            this.$store.dispatch('addDeliveryaddress',JSON.stringify(this.newAddress));
            this.Address = this.inputAddress
            this.changeDelivery = true;
            this.$router.push({name:"Resturants"})
        },
        getAddressData: function (addressData, placeResultData, id) {
            this.newAddress = addressData;
            this.newAddress.address = (addressData.street_number) ?`${addressData.street_number}, ${addressData.route}, ${addressData.locality}, ${addressData.country}`:`${addressData.route} ${addressData.locality}, ${addressData.country}`
            this.inputAddress = this.newAddress.address;
            this.$refs.address.update(this.newAddress.address);
        },
        Unchange(){
            this.changeDelivery = true;
        }
    }
}
</script>

<style>

</style>