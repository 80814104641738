<template>
    <div class="absolute bg-white w-full z-30 shadow-lg rounded p-3 text-sm mt-2" >
        <div @click.self="closeDropdown" class="fixed top-0 left-0 h-full w-full -z-10" ></div>
        <ul v-if="rating == false">
            <li v-for="item in items" :key="item" class="py-0.5">
                <router-link :to="item.url" class="hover:text-brand transition ease-in-out duration-300 text-sm my-2">  {{ item.name }}</router-link>
            </li>
        </ul>
        <ul v-else>
             <li v-for="item in items" :key="item" class="py-0.5">
                <a href="#" @click="sortRating(item.start,item.end)" class="hover:text-brand transition ease-in-out duration-300 text-sm my-2">  {{ item.name }}</a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name:['Dropdown'],
    props:['items','rating'],
    emits:['sortRating','closeDropdwn'],
    methods:{
        closeDropdown(){
            this.$emit('closeDropdwn')
        },
        sortRating(start,end){
            this.$emit('sortRating',{
                start:start,
                end:end
            });
            this.$emit('closeDropdwn');
        }
    }
}
</script>

<style>

</style>