<template>
    <div v-if="type == 'scheduled' && scheduledResturant.length > 0" >
       <div  class="grid mt-4 md:grid-cols-2 lg:grid-cols-4 gap-4"> 
        <div v-for="item in scheduledResturant.slice(0,count)" :key="item" class="bg-black rounded-lg  w-full overflow-hidden shadow-md text-gray-100" >
            <div @click="pushResturant(item,'schedule')" class="relative cursor-pointer">
                <img :src="item.images[0].image" class="h-300 object-cover w-full" alt="Resturant Name">
                <div v-if="item.isOpen == false" class="absolute top-0 text-black h-full w-full bg-overlay"></div>
                <div v-if="item.isOpen == false" class="absolute z-10 top-0 text-black flex justify-center items-center h-full w-full bg-overlay">
                    <div class="text-black bg-gray-100 text-center py-2 w-4/5 text-xs  font-semibold rounded">
                        <div class="mb-1">
                            CLOSED
                        </div>
                        <div>
                            {{ getBusinessHours(item) }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="my-2">
                <span @click="pushResturant(item,'schedule')" class="text-xl lg:text-base cursor-pointer font-semibold m-4 transition duration-300 ease-in text-brand">{{ item.name }}</span>
            </div>
            <div class="text-xs mt-4 px-4">
                 <p class="font-semibold">Meals Available in:</p>
                <div class=" flex flex-wrap text-xs gap-2 py-2 w-42 ">            
                    <div v-for="(category , index) in sortTimebelt(item.availableTimeBelts).filter(item => item.name != 'Instant' )" :key="category" class="flex space-x-2">
                            <span class="capitalize" v-if="index + 1  == item.availableTimeBelts.length" >
                                {{ category.name.toLowerCase() }}
                            </span>
                            <span v-else class="capitalize" >
                                {{ category.name.toLowerCase() }} |
                            </span>
                    </div>
                </div>
            </div>
            <div class="flex flex-grow justify-between px-4 py-2 text-xs">
                <div class="flex items-center space-x-1">
                    <div v-if="item.rating != null && item.rating != 0">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-brand" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                    </div>
                    <p>{{ (item.rating != null && item.rating != 0  && item.rating != 'NaN') ? item.rating.toFixed(1) : 'Not rated'  }} <span @click="getReviews(item.restaurantId,item.rating,item.numberOfReviews)" class="cursor-pointer text-brand" v-if="item.rating != null && item.rating != 0">({{ (item.numberOfReviews != null) ? item.numberOfReviews : 0   }} {{ (item.numberOfReviews > 1) ? 'reviews' : 'review' }})</span></p>
                </div> 
                <div>
                    <span v-if="parseInt(item.distanceToDeliveryAddress) > 1" >{{ item.distanceToDeliveryAddress }}km Away</span>
                    <span v-else>&#60; 1 km Away </span> 
                </div>
            </div>
            <div class="px-4 text-xs pb-2">
                {{ item.totalDeliveredOrders }} orders
            </div>
        </div>
       </div>
        <div v-if="scheduledResturant.length > count " class="flex flex-grow justify-center my-6">
            <button @click="addMore(type)" ref="scheduled" class="bg-brand rounded-md py-4 hover:opacity-80 px-8 text-sm font-semibold">View All</button>
        </div> 
    </div>
    <div class="text-black" v-if="error && itemTopush">
        <Modal :show="true"> 
            <h1 class="text-xl font-semibold">Do you want to proceed?</h1>
            <p class="mt-4 text-sm" > You have tried to add items from different time belts or different restaurants. Changing any of these will clear your current cart and a new one will be created.</p>
            <div class="flex space-x-4 justify-end">
                <button @click="closeModal" class="bg-gray-200 text-sm py-2 px-3 rounded-sm" >No</button>
                <button @click="clearCart(itemTopush,timebeltTopush)" class="bg-brand text-sm py-2 px-3 rounded-sm" >Yes</button>
            </div>
        </Modal>
    </div>
<ReviewsPopup v-if="showReviews" :reviews="reviews" :reviewsData="reviewsData" :rating="rating" @closePopup="toogleReviews"/>
</template>
<script>
import Modal from "@/components/Modal.vue";
import moment from 'moment'
import ReviewsPopup from "@/components/RatingsModal.vue";

export default {
    props:['type','resturants'],
    components:{
        Modal,ReviewsPopup
    },
    data(){
        return{
            instantResturant:[],
            scheduledResturant:[],
            count:12,
            countInstant:12,
            error: false,
            itemTopush:[],
            timebeltTopush:"",
            reviewsData:[],
            showReviews:false,
            reviews:"",
            rating:"",
            openingHours:{}
        }
    },
    computed:{
       
    },  
    methods:{
        pushResturant(item,timebelt){
            console.log(item,'item pub')
            let publicUrl = this.$store.getters.getOrderStoreName;
            let checkCart = this.$store.getters.getCart.cartItems
            if(!publicUrl){
                this.$router.push({ name: 'ResturantsPage', params : { publicUrl: this.convertToUrl(item.publicURL) } })
            }else if (publicUrl == item.publicURL){
                this.$router.push({ name: 'ResturantsPage', params : { publicUrl: this.convertToUrl(item.publicURL) } })
            }else if(checkCart.length < 1){
                 this.$router.push({ name: 'ResturantsPage', params : { publicUrl: this.convertToUrl(item.publicURL)} })
            }else{
                this.error = true;
                this.itemTopush = item
            }
        },
        sortTimebelt(timebelts){
            timebelts.sort(function(a, b) {
                    return a.id - b.id;
                });
            return timebelts;
        },
        convertToUrl(resturantName)
        {
            console.log(resturantName)
            return resturantName.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'');
        },
        closeModal(){
            this.error = false;
        },
        sortResturants(){
            for (let i = 0; i < this.resturants.length; i++) {
                const resturant = this.resturants[i];
                const instant = resturant.availableTimeBelts.find( ({ name }) => name === 'Instant' );
                if(instant){
                    resturant.miniTime = resturant.shortestMealPreparationTime;
                    resturant.maxTime = resturant.longestMealPreparationTime;
                    this.instantResturant.push(resturant)
                }
                const scheduled = resturant.availableTimeBelts.find( ({ name }) => name === 'Breakfast' || name === 'Lunch' || name === 'Dinner' || name === 'Brunch');
                if (scheduled) {
                     this.scheduledResturant.push(resturant);
                }
            }
        },
        clearCart(item){
            this.$store.dispatch('emptyCart')
            this.$store.dispatch('emptyResurantName')
            this.$router.push({ name: 'ResturantsPage', params : { publicUrl: this.convertToUrl(item.publicURL)} })
        },
        addMore(instance){
            if (instance === 'scheduled') {
                if (this.count == this.scheduledResturant.length) {
                    this.count = 12
                    this.$refs.scheduled.innerHTML = "View More";
                    window.scrollTo(0, 100);
                }else{
                    this.count = this.scheduledResturant.length
                    this.$refs.scheduled.innerHTML = "View Less";
                }
            }else if(instance === 'instant'){
                if (this.countInstant == this.instantResturant.length) {
                    this.countInstant = 12
                    window.scrollTo(0, 0);
                    this.$refs.instant.innerHTML = "View More";
                }else{
                    this.countInstant = this.instantResturant.length
                    this.$refs.instant.innerHTML = "View Less";
                }
            }
        },
         toogleReviews(){
            this.showReviews = !this.showReviews
        },
        async getReviews(id,rating,reviews){
            try{
                let url = this.$store.state.base_url + 'review/public/published/restaurant/' + id;
                let res = await this.axios.get(url);
                this.reviewsData = res.data.sort((a, b) =>  new Date(b.createdAt) - new Date(a.createdAt));;
                this.reviews = reviews;
                this.rating = rating;
                this.toogleReviews();
            }catch(err){
                console.log(err)
            }
        },
        convertHoursNicename(hours,minutes){
            let time = (hours > 12) ? 'PM' : 'AM'
            hours = (hours > 12) ? parseInt(hours) - 12 : hours
            return `${hours}:${(minutes > 10 ) ? minutes : 0 + `${minutes}` } ${time}`
        },

        getNextOpeningDay(businessPeriod) {
          for (let i = 1; i <= 7; i++) {
            let dayOfWeek =  moment().add(i, 'days').format('dddd').toLowerCase()
            if(businessPeriod[dayOfWeek].length > 0){
              return dayOfWeek
            }
          }
          return null
        },

        getBusinessHours(item){
            let today = moment().format('dddd').toLowerCase()
            let hour = moment().format('H')


          if(item.businessPeriod[today].length <= 1){
              let nextOpeningDay = this.getNextOpeningDay(item.businessPeriod);
              if(nextOpeningDay == null) return `Opening soon`
                item.businessPeriod[nextOpeningDay][0].startTimeNiceName = this.convertHoursNicename(item.businessPeriod[nextOpeningDay][0].startHour,item.businessPeriod[nextOpeningDay][0].startMinutes)
                item.businessPeriod[nextOpeningDay][0].endTimeNiceName = this.convertHoursNicename(item.businessPeriod[nextOpeningDay][0].endHour, item.businessPeriod[nextOpeningDay][0].endMinutes )
                return `Opening on ${nextOpeningDay} at ${item.businessPeriod[nextOpeningDay][0].startTimeNiceName}`;
            }

            let todayRemaining = item.businessPeriod[today].filter((item) => item.startHour > hour)
            todayRemaining[0].startTimeNiceName = this.convertHoursNicename(todayRemaining[0].startHour,todayRemaining[0].startMinutes)
            todayRemaining[0].endTimeNiceName = this.convertHoursNicename(todayRemaining[0].endHour, todayRemaining[0].endMinutes)
            return `Opening today at ${todayRemaining[0].startTimeNiceName}`;

        }
    },
    mounted(){
        this.sortResturants();  
    }
}
</script>

<style>
.bg-orange{
    background-color: #f97316;
}
</style>